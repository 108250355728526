import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  Layout,
  Input,
  notification,
  Switch,
  Select,
  Space,
  Button,
  Modal,
  Form,
  DatePicker,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import {
  clusterRssFeed,
  createRssFeed,
  fetchRssFeedList,
  patchRssFeed,
} from '../../../services/rssFeedService';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const RssFeed = () => {
  const dispatch = useDispatch();

  const [addNewsModalVisible, setAddNewsModalVisible] = useState(false);
  const [addNewsForm] = Form.useForm();

  const { rssFeedPagination, diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data
      ? state.diseaseTypeReducer.diseaseTypes.data.map(
          ({ krName, enName, id }) => ({
            label: krName,
            en: enName,
            kr: krName,
            value: id,
          }),
        )
      : [];
    diseaseTypes.push({
      label: '공통',
      value: 'common',
    });
    return {
      rssFeedPagination: state?.paginationReducer?.rssFeedPagination,
      diseaseTypeData: diseaseTypes,
    };
  });

  const { Content } = Layout;
  const { Search } = Input;

  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [getData]);

  const fetchList = useCallback(async () => {
    const res = await fetchRssFeedList(
      {
        current: rssFeedPagination.page,
        pageSize: rssFeedPagination.pageSize,
      },
      rssFeedPagination.filter,
      rssFeedPagination.sort,
    );

    dispatch(
      paginationCreators.setValue(
        (rssFeedPagination.page = res.page),
        (rssFeedPagination.totalPage = res.pageCount),
        (rssFeedPagination.totalCount = res.total),
      ),
    );
    setData(res.data);
    setIsLoading(false);
  }, [dispatch, rssFeedPagination]);

  useEffect(() => {
    fetchList();
  }, [
    rssFeedPagination.page,
    rssFeedPagination.pageSize,
    rssFeedPagination.totalPage,
    rssFeedPagination.filter,
    rssFeedPagination.sort,
    fetchList,
  ]);

  const [data, setData] = useState([]);

  const patchIsApp = async (record) => {
    setIsLoading(true);
    try {
      await patchRssFeed(record?.id, {
        isApp: !record?.isApp,
      });
      setIsLoading(false);
      notification.success({
        message: '앱 노출 업데이트',
        description: '앱 노출 업데이트에 성공했습니다.',
      });

      // reload table
      dispatch(
        paginationCreators.setValue(
          (rssFeedPagination.filter = { ...rssFeedPagination.filter }),
        ),
      );
    } catch (error) {
      // 에러 처리
      setIsLoading(false);
      notification.error({
        message: '앱 노출 업데이트',
        description: '앱 노출 업데이트에 실패했습니다.',
      });
      console.error('Error updating filter status:', error);
    }
  };

  const patchFilterStatus = async (record) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setIsLoading(true);
      try {
        await patchRssFeed(record.id, { filterStatus: false });
        setIsLoading(false);
        notification.success({
          message: '콘텐츠 삭제',
          description: '콘텐츠가 삭제되었습니다.',
        });

        // reload table
        dispatch(
          paginationCreators.setValue(
            (rssFeedPagination.filter = { ...rssFeedPagination.filter }),
          ),
        );
      } catch (error) {
        setIsLoading(false);
        notification.error({
          message: '콘텐츠 삭제',
          description: '콘텐츠 삭제에 실패하였습니다.',
        });
        console.error('Error updating filter status:', error);
      }
    }
  };

  const clusterNewsList = () => {
    setIsLoading(true);
    clusterRssFeed()
      .then(() => {
        fetchList();
      })
      .catch((error) => {
        alert(`클러스터링에 실패하였습니다.${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createNews = async (values) => {
    setAddNewsModalVisible(false);
    addNewsForm.resetFields();
    setIsLoading(true);
    createRssFeed({
      ...values,
      diseaseTypeId: values.length > 0 ? values[0] : null,
    })
      .then(() => {
        setTimeout(() => {
          fetchList();
          setIsLoading(false);
        }, 10000);
      })
      .catch((error) => {
        alert(`뉴스 추가에 실패하였습니다.${error.message}`);
        setIsLoading(false);
      })
  };

  const columns = [
    {
      title: '레어노트 업데이트일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      render: (text) => {
        return text && dayjs(text).format('YYYY-MM-DD HH:mm');
      },
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: '질환명',
      dataIndex: 'diseaseTypeName',
      key: 'diseaseTypeName',
      width: '10%',
      sorter: true,
      render: (text) => {
        const result = diseaseTypeData?.find(
          (d) => d.en === text || d.kr === text,
        );
        return <>{result?.kr ?? text}</>;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={rssFeedPagination.filter?.diseaseTypeName ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="질환을 선택하세요"
            options={diseaseTypeData}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      filteredValue: rssFeedPagination.filter?.diseaseTypeName || null,
    },
    {
      title: '국내외',
      dataIndex: 'lang',
      key: 'lang',
      width: '6%',
      filters: [
        {
          text: '국내',
          value: 'ko',
        },
        {
          text: '해외',
          value: 'other',
        },
      ],
      filteredValue: rssFeedPagination.filter?.lang || null,
      sorter: true,
      render: (text, record) => {
        return record?.lang === 'ko' ? '국내' : '해외';
      },
    },
    {
      title: '기사 title, image, site_name, description',
      dataIndex: 'title',
      key: 'title',
      width: '45%',
      sorter: true,
      filterIcon: () => <></>,
      filteredValue: rssFeedPagination.filter?.title || null,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div>
            {record?.image && (
              <img
                style={{ width: 80, marginRight: 8 }}
                src={record?.image}
                alt=""
              />
            )}
          </div>
          <div>
            <div>
              <a
                href={record?.url}
                target="_blank"
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                rel="noreferrer noopener"
              >
                {record?.title}
              </a>
            </div>
            <div className="rssFeedDescription">{record?.description}</div>
          </div>
        </div>
      ),
    },
    {
      title: '기사 발행일',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      width: '10%',
      sorter: true,
      render: (text) => {
        return text && dayjs(text).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '주제별 태그',
      dataIndex: 'tag',
      key: 'tag',
      width: '11%',
      sorter: true,
      filters: [
        {
          text: '질환 정보',
          value: '질환 정보',
        },
        {
          text: '치료와 관리',
          value: '치료와 관리',
        },
        {
          text: '정책',
          value: '정책',
        },
        {
          text: '환자 이야기',
          value: '환자 이야기',
        },
        {
          text: '환자 지원',
          value: '환자 지원',
        },
        {
          text: '행사',
          value: '행사',
        },
      ],
      filteredValue: rssFeedPagination.filter?.tag || null,
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '6%',
      sorter: true,
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: rssFeedPagination.filter?.isApp || null,
      render: (text, record) => (
        <div>
          <Switch
            checked={record?.isApp}
            size="small"
            onClick={() => patchIsApp(record)}
          />
        </div>
      ),
    },
    {
      title: '유사도 그룹',
      dataIndex: 'groupId',
      key: 'groupId',
      width: '6%',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'filterStatus',
      key: 'filterStatus',
      width: '5%',
      render: (text, record) => (
        <div>
          {record?.isApp ? (
            <div>-</div>
          ) : (
            <Button
              type="link"
              size="small"
              style={{ textDecoration: 'underline' }}
              onClick={() => patchFilterStatus(record)}
            >
              삭제
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="rssFeed" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="글로벌 뉴스"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button type="primary" onClick={() => clusterNewsList()}>
              유사도 클러스터링
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10, marginBottom: 16 }}
              onClick={() => setAddNewsModalVisible(true)}
            >
              뉴스 추가하기
            </Button>
            <Modal
              title="뉴스 추가하기"
              visible={addNewsModalVisible}
              onOk={() => {
                addNewsForm.submit();
              }}
              onCancel={() => {
                setAddNewsModalVisible(false);
                addNewsForm.resetFields();
              }}
              >
                <Form
                  onFinish={createNews}
                  form={addNewsForm}
                >
                  <Form.Item label="기사 URL" name="url"  rules={[{ required: true, message: '기사 URL을 입력해주세요.' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="질환 선택" name="diseaseTypeId">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="질환을 선택해주세요."
                      filterOption={(input, option) =>
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                          0}
                      onChange={(value) => {
                        if (value.length > 1) {
                          // 한 개만 선택되도록 제어
                          addNewsForm.setFieldsValue({ diseaseTypeId: [value[value.length - 1]] });
                        }
                      }}
                    >
                      {diseaseTypeData.map((diseaseType) => (
                        <Select.Option
                          key={diseaseType?.label}
                          id={diseaseType?.label}
                          value={diseaseType?.value}
                        >
                          {diseaseType?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="국내/해외 선택" name="lang" rules={[{required: true, message: '국내/해외 선택해주세요.'}]} hasFeedback>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      filterOption={(input, option) => option.key.includes(input)}
                    >
                      <Select.Option
                        id={'ko'}
                        value={'ko'}
                      >
                        국내
                      </Select.Option>
                      <Select.Option
                        id={'en'}
                        value={'en'}
                      >
                        해외
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="제목" name="title"  rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="본문" name="description"  rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item label="기사발행일" name="publishedDate"  rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="사이트명" name="siteName"  rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                setIsLoading(true);
                dispatch(
                  paginationCreators.setValue(
                    (rssFeedPagination.filter = {
                      ...rssFeedPagination.filter,
                      title: [value],
                    }),
                  ),
                );
              }}
            />
            <div className="searchResult">
              {`검색결과 ${rssFeedPagination.totalCount}개`}
            </div>

            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                onChange: (page) => {
                  dispatch(
                    paginationCreators.setValue(
                      (rssFeedPagination.page = page),
                    ),
                  );
                },
                current: rssFeedPagination.page,
                defaultPageSize: rssFeedPagination.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
                total: rssFeedPagination.totalCount,
              }}
              onChange={(pagination, filter, sorter) => {
                setIsLoading(true);
                dispatch(
                  paginationCreators.setValue(
                    (rssFeedPagination.page = pagination.current),
                    (rssFeedPagination.pageSize = pagination.pageSize),
                    (rssFeedPagination.filter = {
                      ...filter,
                      title: rssFeedPagination.filter.title ?? [],
                    }),
                    (rssFeedPagination.sort = sorter),
                  ),
                );
              }}
              size="small"
              bordered
              rowKey={(record) => record.id}
              loading={isLoading}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RssFeed;
