import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Layout,
  Table,
  Input,
  Row,
  notification,
  Typography,
  Select,
  Modal,
  Form,
  InputNumber,
  Space,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import {
  HEALTH_PROFILE_GROUP_GENDER,
  HEALTH_PROFILE_GROUP_STATUS,
} from '../../../util/healthProfileGroupConstants';
import {
  fetchHealthProfileGroup,
  fetchHealthProfileGroupExcel,
  fetchHealthProfileGroupUsers,
  postHealthProfileGroupInstitutions,
  postHealthProfileGroupPush,
} from '../../../services/healthProfileGroupService';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const HealthProfileGroupUsers = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pagination, diseaseTypes } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data
      ? state.diseaseTypeReducer.diseaseTypes.data.map(({ krName, id }) => ({
          label: krName,
          value: id,
        }))
      : [];
    return {
      pagination: state?.paginationReducer?.healthProfileGroupUserPagination,
      diseaseTypes,
    };
  }, shallowEqual);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState(null);
  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const [selectedRound, setSelectedRound] = useState(null);

  // 필터 선택지를 위한 데이터
  const [healthProfiles, setHealthProfiles] = useState([]);

  const [qrModalLoading, setQrModalLoading] = useState(false);
  const [exportCsvLoading, setExportCsvLoading] = useState(false);

  const getData = useCallback(async () => {
    if (!state.groupId) return;
    if (pagination.groupId !== state.groupId) {
      dispatch(
        paginationCreators.setValue(
          (pagination.groupId = state.groupId),
          (pagination.page = 1),
          (pagination.filter = {}),
          (pagination.pageSize = 20),
        ),
      );
    }
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    const group = await fetchHealthProfileGroup(state.groupId);
    setGroupData(group);

    const profiles = [];
    group.rounds.forEach((round) => {
      round.healthProfiles?.forEach((profile) => {
        if (!profiles.find((p) => p.id === profile.id)) {
          profiles.push(profile);
        }
      });
    });

    setHealthProfiles(profiles);
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const params = {
      limit: pagination.pageSize,
      offset: (pagination.page - 1) * pagination.pageSize,
    };

    const order = pagination.sorter?.column
      ? `${pagination.sorter?.order === 'descend' ? '-' : ''}${
          pagination.sorter?.column.dataIndex
        }`
      : null;

    // 정렬
    if (order) params['order'] = order;

    // 필터
    Object.entries(pagination.filter ?? {}).forEach(([key, value]) => {
      if (key === 'email' && value?.[0].length > 0) {
        params['keyword'] = value[0];
      } else {
        if (value?.length > 0) {
          params[key] = value.join(',');
          if (key.startsWith('is') && value.length > 1) {
            // Boolean 타입을 모두 선택한 경우 필터 적용되지 않도록 변경
            delete params[key];
          }
        }
      }
    });
    const { results: users, total } = await fetchHealthProfileGroupUsers(
      state.groupId,
      params,
    );
    setDataSource(users);
    setTotal(total);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const { Search } = Input;
  const { Content } = Layout;

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () =>
      history.push('/userDetail', {
        userId,
        dataKey: 'healthProfileGroupSelected',
      }),
    [history],
  );

  const CompletedTag = (completed) => {
    if (!completed) return '-';
    const color =
      completed === HEALTH_PROFILE_GROUP_STATUS.COMPLETED
        ? '#1890ff'
        : completed === HEALTH_PROFILE_GROUP_STATUS.IN_PROGRESS
        ? '#ef5855'
        : '#979797';
    return (
      <div
        className="tagWrapper"
        style={{
          color,
        }}
      >
        {HEALTH_PROFILE_GROUP_STATUS[completed]}
      </div>
    );
  };

  const defaultColumns = useMemo(
    () => [
      {
        title: '설문 차수',
        dataIndex: 'round',
        key: 'round',
        filters: groupData?.rounds.map((_, index) => ({
          text: `${index + 1}차 설문`,
          value: index + 1,
        })),
        filteredValue: pagination.filter?.round || null,
        render: (round) => (round ? `${round}차 설문` : '-'),
        sorter: true,
      },
      {
        title: '최종 설문',
        dataIndex: 'healthProfileName',
        filters: healthProfiles?.map((profile) => ({
          text: profile.type,
          value: profile.id,
        })),
        filteredValue: pagination.filter?.healthProfileName || null,
        key: 'healthProfileId',
        sorter: true,
        render: (healthProfileName, record) => (
          <Button
            type="link"
            onClick={() => {
              history.push('/healthProfileUsers', {
                healthProfileId: record.healthProfileId,
                title: healthProfileName,
              });
            }}
            style={{
              width: '100%',
              textAlign: 'left',
              whiteSpace: 'normal',
            }}
          >
            {healthProfileName}
          </Button>
        ),
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        render: CompletedTag,
        filters: Object.entries(HEALTH_PROFILE_GROUP_STATUS).map(
          ([key, value]) => ({
            text: value,
            value: key,
          }),
        ),
        filteredValue: pagination.filter?.status || null,
        sorter: true,
      },
      {
        title: '최초 응답일',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) =>
          // NOTE(reo): 설문은 서버에서 타임존 이슈가 있어 보정하여 표시
          createdAt
            ? moment(createdAt).add(9, 'hours').format('YYYY-MM-DD HH:mm')
            : '-',
        sorter: true,
      },
      {
        title: '업데이트일',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt) =>
          updatedAt
            ? moment(updatedAt).add(9, 'hours').format('YYYY-MM-DD HH:mm')
            : '-',
        sorter: true,
      },
      {
        title: '계정',
        dataIndex: 'email',
        sorter: true,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
          confirmRef.current = (searchWord) => {
            if (searchWord) setSelectedKeys([searchWord]);
            else clearFilters();
            confirm();
          };
          return <></>;
        },
        filterIcon: () => <></>,
        render: (text, record) =>
          text || record.socialUid ? (
            <>
              {text || record.socialUid}
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={handleMoveDetailPage(record)}
              >
                이동
              </Button>
            </>
          ) : (
            '-'
          ),
      },
      {
        title: '이름',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '성별',
        dataIndex: 'gender',
        key: 'gender',
        filteredValue: pagination.filter?.gender || null,
        filters: Object.entries(HEALTH_PROFILE_GROUP_GENDER).map(
          ([key, value]) => ({
            text: value,
            value: key.toLowerCase(),
          }),
        ),
        render: (gender) => HEALTH_PROFILE_GROUP_GENDER[gender] ?? '-',
      },
      {
        title: '질환',
        dataIndex: 'diseaseTypeName',
        key: 'diseaseTypeId',
        filters: diseaseTypes,
        filteredValue: pagination.filter?.diseaseTypeId || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Select
              style={{
                marginRight: 8,
                width: 250,
              }}
              placement="bottomLeft"
              defaultValue={pagination.filter?.diseaseTypeId ?? null}
              value={selectedKeys}
              showSearch
              mode="multiple"
              placeholder="질환을 선택하세요"
              options={diseaseTypes}
              filterOption={(input, { label }) =>
                label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => setSelectedKeys(e)}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters()}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
      },
      {
        title: '계정 유형',
        dataIndex: 'relationship',
        key: 'relationship',
      },
      {
        title: '14세 미만',
        dataIndex: 'isNOK',
        key: 'isNOK',
        render: (isNOK, record) => (
          <>{record.userId ? (isNOK ? 'O' : 'X') : '-'}</>
        ),
        filters: [
          {
            text: 'O',
            value: true,
          },
          {
            text: 'X',
            value: false,
          },
        ],
        filteredValue: pagination.filter?.isNok || null,
      },
      {
        title: '테스트ID',
        dataIndex: 'isTest',
        key: 'isTest',
        render: (isTest, record) => (
          <>{record.userId ? (isTest ? 'O' : 'X') : '-'}</>
        ),
        filters: [
          {
            text: 'O',
            value: true,
          },
          {
            text: 'X',
            value: false,
          },
        ],
        filteredValue: pagination.filter?.isTest || null,
      },
    ],
    [groupData, dataSource],
  );

  const columns = useMemo(() => {
    const cols = [...defaultColumns];
    if (dataSource.length > 0 && dataSource.find((d) => d.institutionName)) {
      cols.push(
        {
          title: '병원명',
          dataIndex: 'institutionName',
          key: 'institutionName',
          filters: groupData.institutions.map((institution) => ({
            text: institution.title,
            value: institution.title,
          })),
          filteredValue: pagination.filter?.institutionName || null,
        },
        {
          title: '연구 대상자 번호',
          dataIndex: 'subjectNumber',
          key: 'subjectNumber',
          sorter: true,
        },
        {
          title: 'QR 코드',
          dataIndex: 'qrLink',
          key: 'qrLink',
          render: (link, record) => (
            <>
              <Button
                icon={<DownloadOutlined />}
                onClick={(e) => {
                  const svgElement = e.currentTarget.nextSibling;
                  if (!svgElement) {
                    notification.error({
                      message: 'QR 코드가 생성되지 않았습니다.',
                      key: 'qrCodeNotGenerated',
                    });
                    return;
                  }
                  const serialized = new XMLSerializer().serializeToString(
                    svgElement,
                  );
                  const blob = new Blob([serialized], {
                    type: 'image/svg+xml;charset=utf-8',
                  });
                  const a = document.createElement('a');
                  a.href = URL.createObjectURL(blob);
                  a.download = `${record.subjectNumber}.svg`;
                  a.click();
                }}
                disabled={!link}
              />
              <QRCodeSVG value={link} hidden />
            </>
          ),
        },
      );
    }
    return cols;
  }, [dataSource]);

  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrForm] = Form.useForm();
  const onCreateQR = async (values) => {
    if (!window.confirm('생성하시겠습니까?')) return;
    setQrModalLoading(true);
    await postHealthProfileGroupInstitutions(state.groupId, values);
    window.alert('생성되었습니다.');
    setQrModalVisible(false);
    qrForm.resetFields();
    getData();
    fetchUsers();
    setQrModalLoading(false);
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfileGroup" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="주기적 설문 응답 유저 리스트"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{groupData?.title}</Typography.Title>
            <Row style={{ gap: 6, marginBottom: 8 }}>
              <Button
                type="primary"
                onClick={() => history.push('/healthProfileGroup')}
              >
                목록 돌아가기
              </Button>
              <Row>
                <Select
                  options={groupData?.rounds.map((_, index) => ({
                    value: index + 1,
                    label: `${index + 1}차 설문`,
                  }))}
                  placeholder="설문 차수 선택"
                  onChange={setSelectedRound}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await postHealthProfileGroupPush(
                      state.groupId,
                      selectedRound,
                    );
                    notification.success({
                      message: '푸시 발송 완료',
                    });
                  }}
                  disabled={!selectedRound}
                >
                  미참여 유저 독려 푸시 발송
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRound === null}
                  onClick={async () => {
                    setExportCsvLoading(true);
                    try {
                      await fetchHealthProfileGroupExcel(
                        state.groupId,
                        selectedRound,
                      );
                    } catch (e) {
                      notification.error({
                        message: '엑셀 출력에 실패했습니다.',
                        description:
                          '문제가 지속될 경우 관리자에게 문의해 주세요.',
                        key: 'exportCsvError',
                      });
                    }
                    setExportCsvLoading(false);
                  }}
                  loading={exportCsvLoading}
                >
                  엑셀 출력
                </Button>
              </Row>
              <Button
                type="primary"
                onClick={() => {
                  setQrModalVisible(true);
                }}
              >
                병원별 연구 대상자 QR 생성
              </Button>
              <Modal
                title="병원별 연구 대상자 QR 생성"
                visible={qrModalVisible}
                onOk={qrForm.submit}
                onCancel={() => {
                  setQrModalVisible(false);
                  qrForm.resetFields();
                }}
                confirmLoading={qrModalLoading}
              >
                <Form
                  onFinish={onCreateQR}
                  form={qrForm}
                  initialValues={{
                    title: '',
                    subjectCount: 1,
                  }}
                >
                  <Form.Item label="주기적 설문 그룹명(연구명)">
                    <Typography.Text>{groupData?.title}</Typography.Text>
                  </Form.Item>
                  <Form.Item
                    label="병원명"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: '병원명을 입력하세요.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="연구 인원"
                    name="subjectCount"
                    rules={[
                      {
                        required: true,
                        message: '연구대상자 수를 입력하세요.',
                      },
                    ]}
                    tooltip="연구 인원 수에 맞춰 주기적 설문에 연결되는 QR 코드 + 앱딥링크 + 연구 대상자 번호가 생성됩니다."
                  >
                    <InputNumber min={1} addonAfter="명" />
                  </Form.Item>
                  <Typography.Text type="warning">
                    *연구 대상자 QR 코드는 생성 후 취소가 불가능하니, 정보가
                    정확한지 반드시 확인해 주세요.
                  </Typography.Text>
                </Form>
              </Modal>
            </Row>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
              }}
              defaultValue={pagination.filter?.email?.[0]}
            />
            <div className="searchResult">검색결과 {total}개</div>
            <Table
              rowKey={(record) =>
                `${record.userId || 'undefined'}-${record.institutionName}-${
                  record.subjectNumber
                }`
              }
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                total: total,
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(paginationValue, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (pagination.page = paginationValue?.current),
                    (pagination.pageSize = paginationValue?.pageSize),
                    (pagination.filter = filters),
                    (pagination.sorter = sorter),
                  ),
                );
                fetchUsers();
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfileGroupUsers;
