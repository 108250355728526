import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import 'antd/dist/antd.css';
import { DownloadOutlined } from '@ant-design/icons';
import { userCreators } from '../../../store/reducers/user.reducer';
import { fetchHealthProfileQuestionsHealthProfileAnswers } from '../../../services/healthProfileService';
import * as permissions from '../../../util/permission';

const projects = {
  keye: '카이안과',
};

const userInfoCommonQuestions = [
  {
    text: '이메일',
    value: 'email',
    type: 'userInfo',
  },
  {
    text: '이름',
    value: 'name',
    type: 'userInfo',
  },
  {
    text: '연락처',
    value: 'phone',
    type: 'userInfo',
  },
  {
    text: '성별',
    value: 'gender',
    type: 'userInfo',
  },
  {
    text: '질환',
    value: 'diseaseType',
    type: 'userInfo',
  },
];

const userInfoQuestionsByProjectName = {
  keye: [
    {
      text: '신청일',
      value: 'createdAt',
      type: 'userInfo',
    },
    {
      text: '유입경로',
      value: 'funnel',
      type: 'userInfo',
    },
    {
      text: '생년월일',
      value: 'birthDate',
      type: 'userInfo',
    },
  ],
  nf1: [
    {
      text: '생년월일',
      value: 'birthDate',
      type: 'userInfo',
    },
    {
      text: '보호자 이름(14세 미만일 경우만 있음)',
      value: 'parentName',
      type: 'userInfo',
    },
    {
      text: '보호자 연락처',
      value: 'parentPhone',
      type: 'userInfo',
    },
  ],
  dms: [
    {
      text: '생년월일',
      value: 'birthDate',
      type: 'userInfo',
    },
    {
      text: '관계',
      value: 'relationship',
      type: 'userInfo',
    },
    {
      text: '테스트계정여부',
      value: 'isTest',
      type: 'userInfo',
    },
  ],
};

const GeneExcelDown = () => {
  const dispatch = useDispatch();
  const { permission } = useSelector((state) => ({
    permission: state?.userReducer.permission.data,
  }));

  const getData = useCallback(() => {
    dispatch(userCreators.fetchAllUser.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  const excelDown = (data, projectName) => {
    const xlsx = require('xlsx');
    const book = xlsx.utils.book_new();

    Object.entries(data?.questionsByType).forEach(([type, questions]) => {
      const rows = [];
      const sheetTitle = type.slice(0, 31);

      const transpose = (matrix) => {
        const [row] = matrix;
        return row.map((value, column) => matrix.map((trans) => trans[column]));
      };

      const userInfoQuestions = userInfoQuestionsByProjectName[
        projectName
      ].concat(userInfoCommonQuestions);

      // excelHeader
      rows.push([
        ...userInfoQuestions.map((answerData) => answerData?.text),
        ...questions?.map((answerData) => answerData?.value),
      ]);

      // excelBody
      data.profiles.forEach((answers) => {
        const values = [];
        userInfoQuestions.forEach((question) => {
          values.push(answers[question?.value]);
        });
        questions.forEach((question) => {
          values.push(answers[question?.id]?.join(', '));
        });
        rows?.push(values);
      });

      const transformedData = transpose(rows);

      const excelData = xlsx.utils.aoa_to_sheet(transformedData);
      xlsx.utils.book_append_sheet(book, excelData, sheetTitle);
    });

    xlsx.writeFile(
      book,
      `${dayjs().format(
        `[${projects[projectName].replace(/ /g, '_')}_건강설문_]YYYY_MM_DD`,
      )}.xlsx`,
    );
    notification.success({
      message: '건강설문 다운로드가 완료 되었습니다.',
      description: '건강설문 다운로드가 완료 되었습니다. ',
    });
  };

  const excelRequest = useCallback(
    (projectName) => () =>
      fetchHealthProfileQuestionsHealthProfileAnswers(projectName)
        .then((data) => excelDown(data, projectName))
        .catch(
          ({
            _data,
            message = `${projects[projectName]} 건강설문 다운로드에 실패했습니다.`,
          }) => {
            notification.warn({
              message: '건강설문 다운로드에 실패했습니다.',
              description: message,
            });
          },
        ),
    [],
  );

  return (
    <div>
      <div style={{ marginBottom: '-32px' }}>
        {permission?.includes(permissions.ACCOUNT_USER) && (
          <Button
            type="primary"
            icon={<DownloadOutlined style={{ verticalAlign: 'baseline' }} />}
            style={{ marginRight: 10 }}
            onClick={excelRequest('keye')}
          >
            카이안과 설문 다운
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeneExcelDown;
