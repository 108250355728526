import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

const ALERT_THRESHOLD_MINUTE = 60 * 60; // 1시간부터 5분 단위로 갱신
const ALERT_THRESHOLD_SECOND = 60; // 1분부터는 초단위로 갱신

const useSessionAlert = () => {
  const [remaining, setRemaining] = useState(ALERT_THRESHOLD_MINUTE + 10); // 최초 렌더링시 만료 메시지가 표시되지 않도록
  const { accessToken, expiresAt } = useSelector(
    (state) => state.authReducer.auth?.data,
  );

  useEffect(() => {
    if (!expiresAt) return;
    const i = setInterval(() => {
      setRemaining(
        expiresAt ? (new Date(expiresAt) - new Date()) / 1000 : null,
      );
    }, 1000);
    return () => clearInterval(i);
  }, [expiresAt]);

  useEffect(() => {
    if (!accessToken) return;
    if (remaining < 1) {
      notification.close('sessionAlert');
      notification.error({
        key: 'sessionExpiredAlert',
        message: '세션이 만료되었습니다.',
        description: '다시 로그인해주세요. 저장되지 않은 내용은 소실됩니다.',
        duration: 0,
        placement: 'topLeft',
      });
      return;
    }
    if (remaining < ALERT_THRESHOLD_SECOND) {
      notification.warn({
        key: 'sessionAlert',
        message: '로그인 세션이 만료 직전입니다.',
        description: (
          <>
            세션 연장 또는 재로그인해주세요. 작업중인 내용이 소실될 수 있습니다.{' '}
            <br />
            <b>{Math.floor(remaining)}초</b> 후에 세션이 만료됩니다.
          </>
        ),
        duration: 0,
        placement: 'topLeft',
      });
      return;
    }
    if (
      remaining < ALERT_THRESHOLD_MINUTE &&
      Math.floor(remaining) % (5 * 60) === 0
    ) {
      notification.warn({
        key: 'sessionAlert',
        message: '로그인 세션이 만료 직전입니다.',
        description: (
          <>
            세션 연장 또는 재로그인해주세요. 작업중인 내용이 소실될 수 있습니다.{' '}
            <br />
            <b>{Math.floor(remaining / 60)}분</b> 후에 세션이 만료됩니다.
          </>
        ),
        duration: 0,
        placement: 'topLeft',
      });
      return;
    }
  }, [remaining]);

  return;
};

export default useSessionAlert;
