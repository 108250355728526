import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import App from './App';

import store from './store';

import { setInterceptors } from './util/axiosUtils';

import './index.css';
import './public/css/bootstrapcustom.css';
import { UNAUTHORIZED } from './services/utils';

// setBaseURL의 주석 참조
// setBaseURL(BaseURL);
setInterceptors();

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

if (process.env.REACT_APP_DEPLOY !== 'local') {
  Sentry.init({
    dsn: sentryDSN,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend(event) {
      if (event.extra) {
        if (event.extra.__serialized__) {
          const { data } = event.extra.__serialized__;
          if (data && data.status === UNAUTHORIZED) {
            // unAuthorized 오류는 센트리에 기록하지 않음
            return null;
          }
        }
      }
      return event;
    },
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
