import axios from 'axios';
import { HTTP_SUCCESS, SUCCESS } from './utils';

/**
 * Raising Awareness Program 목록을 가져옵니다.
 */
export const fetchRaPrograms = async () => {
  const res = await axios.get('/v1/admin/ra/programs');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 단일 Raising Awareness Program을 가져옵니다.
 * @param {uuid} id
 */
export const fetchRaProgram = async (id) => {
  const res = await axios.get(`/v1/admin/ra/programs/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program을 생성합니다.
 * @param {Object} data
 */
export const postRaProgram = async (data) => {
  const res = await axios.post('/v1/admin/ra/programs', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program을 수정합니다.
 * @param {uuid} id
 * @param {Object} data
 */
export const patchRaProgram = async (id, data) => {
  const res = await axios.patch(`/v1/admin/ra/programs/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 여러 개의 Raising Awareness Program을 수정합니다.
 */
export const patchRaProgramBulk = async (data) => {
  const res = await axios.patch('/v1/admin/ra/programs', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program의 순서를 변경합니다.
 * @param {uuid[]} raProjectIds
 */
export const patchRaProgramOrder = async (raProjectIds) => {
  const res = await axios.put(`/v1/admin/ra/programs/order`, raProjectIds);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program을 복사합니다.
 */
export const postRaProgramCopy = async (id) => {
  const res = await axios.post(`/v1/admin/ra/programs/${id}/copies`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program을 삭제합니다.
 */
export const deleteRaProgram = async (id) => {
  const res = await axios.delete(`/v1/admin/ra/programs/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * Raising Awareness Program의 데이터를 엑셀로 추출합니다.
 */
export const fetchRaProgramExcel = async (id, fileName = 'RA 데이터') => {
  const res = await axios.get(
    `/v1/admin/excel?type=RA_PROGRAM&targetId=${id}`,
    {
      responseType: 'arraybuffer',
    },
  );

  let name = `${fileName}.csv`;

  const disposition = res.headers['content-disposition'];
  if (disposition && disposition.includes('filename=')) {
    const matches = disposition.match(/filename="(.+)"/);
    if (matches != null && matches[1]) {
      name = matches[1];
    }
  }

  const blob = new Blob([res.data], {
    type: 'text/csv',
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name;

  link.click();

  URL.revokeObjectURL(link.href);
};

/**
 * Raising Awareness Program의 페이지 목록을 가져옵니다.
 * @param {uuid} id
 */
export const fetchRaPages = async (id) => {
  const res = await axios.get(`/v1/admin/ra/programs/${id}/pages`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 소개 페이지를 가져옵니다.
 * @param {uuid} projectId
 * @param {uuid} pageId
 */
export const fetchRaIntroPage = async (projectId, pageId) => {
  const res = await axios.get(
    `/v1/admin/ra/programs/${projectId}/pages/intro/${pageId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 소개 페이지를 생성합니다.
 * @param {uuid} raProjectId
 * @param {Object} data
 */
export const postRaIntroPage = async (raProjectId, data) => {
  const res = await axios.post(
    `/v1/admin/ra/programs/${raProjectId}/pages/intro`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 소개 페이지를 수정합니다.
 * @param {uuid} raProjectId
 * @param {uuid} pageId
 * @param {Object} data
 */
export const patchRaIntroPage = async (raProjectId, pageId, data) => {
  const res = await axios.patch(
    `/v1/admin/ra/programs/${raProjectId}/pages/intro/${pageId}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 문항/답변 페이지를 가져옵니다.
 * @param {uuid} projectId
 * @param {uuid} pageId
 */
export const fetchRaQuestionPage = async (projectId, pageId) => {
  const res = await axios.get(
    `/v1/admin/ra/programs/${projectId}/pages/survey/${pageId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 문항/답변 페이지를 생성합니다.
 * @param {uuid} raProjectId
 * @param {Object} data
 */
export const postRaQuestionPage = async (raProjectId, data) => {
  const res = await axios.post(
    `/v1/admin/ra/programs/${raProjectId}/pages/survey`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 문항/답변 페이지를 수정합니다.
 * @param {uuid} raProjectId
 * @param {uuid} pageId
 * @param {Object} data
 */
export const patchRaQuestionPage = async (raProjectId, pageId, data) => {
  const res = await axios.put(
    `/v1/admin/ra/programs/${raProjectId}/pages/survey/${pageId}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 결과 페이지를 가져옵니다.
 * @param {uuid} projectId
 * @param {uuid} pageId
 */
export const fetchRaResultPage = async (projectId, pageId) => {
  const res = await axios.get(
    `/v1/admin/ra/programs/${projectId}/pages/result/${pageId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 결과 페이지를 생성합니다.
 * @param {uuid} raProjectId
 * @param {Object} data
 */
export const postRaResultPage = async (raProjectId, data) => {
  const res = await axios.post(
    `/v1/admin/ra/programs/${raProjectId}/pages/result`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 결과 페이지를 수정합니다.
 * @param {uuid} raProjectId
 * @param {uuid} pageId
 * @param {Object} data
 */
export const patchRaResultPage = async (raProjectId, pageId, data) => {
  const res = await axios.patch(
    `/v1/admin/ra/programs/${raProjectId}/pages/result/${pageId}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 문항 페이지 변수명 목록을 조회합니다.
 * @param {uuid} raProjectId
 */
export const fetchRaQuestionVariables = async (raProjectId) => {
  const res = await axios.get(
    `/v1/admin/ra/programs/${raProjectId}/pages/survey-variables`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 페이지들을 삭제합니다.
 * @param {uuid} raProjectId
 * @param {string} pageType
 * @param {uuid[]} raPageIds
 */
export const deleteRaPages = async (raProjectId, pageType, raPageIds) => {
  // 빈 배열로 요청시 bad request 발생
  if (raPageIds.length === 0) return;
  const res = await axios.delete(`/v1/admin/ra/programs/${raProjectId}/pages`, {
    data: {
      type: pageType,
      ids: raPageIds,
    },
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 문항 페이지의 순서를 변경합니다.
 * @param {uuid} raProjectId
 * @param {uuid[]} raPageIds
 */
export const patchRaQuestionPageOrder = async (raProjectId, raPageIds) => {
  const res = await axios.put(
    `/v1/admin/ra/programs/${raProjectId}/pages/survey-orders`,
    raPageIds,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 페이지들을 복사합니다.
 * @param {uuid} raProjectId
 * @param {string} pageType
 * @param {uuid[]} raPageIds
 */
export const copyRaPages = async (raProjectId, pageType, raPageIds) => {
  // 빈 배열로 요청시 bad request 발생
  if (raPageIds.length === 0) return;
  const res = await axios.post(
    `/v1/admin/ra/programs/${raProjectId}/pages/copies`,
    {
      type: pageType,
      ids: raPageIds,
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 유저 응답 목록을 가져옵니다.
 * @param {uuid} projectId
 */
export const fetchRaUserResponses = async (projectId) => {
  const res = await axios.get(`/v1/admin/ra/programs/${projectId}/users`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 특정 유저의 RA 응답을 가져옵니다.
 * @param {uuid} projectId
 * @param {uuid} userId
 */
export const fetchRaUserResponse = async (projectId, userId) => {
  const res = await axios.get(
    `/v1/admin/ra/programs/${projectId}/users/${userId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 특정 유저들의 RA 응답을 초기화합니다.
 * @param {uuid} projectId
 * @param {uuid[]} userIds
 */
export const deleteRaUserResponses = async (projectId, userIds) => {
  const res = await axios.delete(`/v1/admin/ra/programs/${projectId}/users`, {
    data: {
      userIds,
    },
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 특정 유저의 유전자 검사 목록을 조회합니다.
 * @param {uuid} userId
 */
export const fetchRaUserDnaReports = async (userId) => {
  const res = await axios.get(`/v1/admin/ra/users/${userId}/dnaTestReports`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * 특정 유저의 유전자 검사 내역을 업데이트합니다.
 * @param {uuid} reportId
 */
export const patchRaUserDnaReport = async (reportId, data) => {
  const res = await axios.patch(
    `/v1/admin/ra/dnaTestReports/${reportId}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 프로그램의 유전자 검사 목록을 조회합니다.
 */
export const fetchRaDnaReports = async () => {
  const res = await axios.get(`/v1/admin/ra/dnaTestReports`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};

/**
 * RA 프로그램의 유전자 검사 결과지를 삭제합니다.
 * @param {uuid} reportId
 */
export const deleteRaDnaReport = async (reportId) => {
  const res = await axios.delete(`/v1/admin/ra/dnaTestReports/${reportId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
};
