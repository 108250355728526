import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Upload,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  deleteRaDnaReport,
  fetchRaUserDnaReports,
  patchRaUserDnaReport,
} from '../../../services/raisingAwarenessService';
import * as listMap from '../../../util/listMap';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import useUploadFile from '../../../window/util/useUploadFile';
import { getFileInfo } from '../../../services/fileUploadService';

const RegisterForm = ({ item, remove }) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const {
    publicUrl: distinctPublicUrl,
    fileId: distinctFileId,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useUploadFile(distinctFileInputEvent, 'RA_DNA_TEST_REPORT');

  const uploadThumbImg = async (e, idx) => {
    if (!e || e.file?.error || e.event) return;
    if (e !== undefined && e.file.status) {
      setCurrentImgIndex(idx);
      setDistinctFileInputEvent(e);
      distinctImageUpload(e);
    }
  };

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '썸네일 등록에 성공하였습니다.',
      });

      form.setFields([
        {
          name: ['images', currentImgIndex, 'url'],
          value: distinctPublicUrl,
        },
        {
          name: ['images', currentImgIndex, 'id'],
          value: distinctFileId,
        },
      ]);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const onSubmit = async (values) => {
    const data = {
      imageIds: values.images.map((img) => img.id),
      comment: values.comment,
      status: values.status,
    };
    if (!window.confirm('저장하시겠습니까?')) return;
    try {
      await patchRaUserDnaReport(item.id, data);
      notification.success({
        message: '저장에 성공했습니다.',
        key: 'patchRaUserDnaReportSuccess',
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: '저장에 실패했습니다.',
        key: 'patchRaUserDnaReportFailed',
      });
    }
  };

  const onDelete = async () => {
    if (!window.confirm('신청내역을 삭제하시겠습니까?')) return;
    try {
      await deleteRaDnaReport(item.id);
      notification.success({
        message: '삭제에 성공했습니다.',
        key: 'patchRaUserDnaReportSuccess',
      });
      remove?.(item.id);
    } catch (e) {
      console.log(e);
      notification.error({
        message: '삭제에 실패했습니다.',
        key: 'patchRaUserDnaReportFailed',
      });
    }
  };

  const onDownload = async (id) => {
    const { name, url } = await getFileInfo(id);
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  return (
    <Form
      name="basic"
      form={form}
      {...listMap.layout}
      initialValues={item}
      onFinish={onSubmit}
    >
      <Row span={24}>
        <Col span={12}>
          <Form.Item
            label="신청일"
            name="createdAt"
            getValueProps={(v) => ({
              value: moment(v).format('YY.MM.DD HH:mm'),
            })}
          >
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="종류">
            <Input readOnly value="RA 유전자 검사" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="사진"
        shouldUpdate={(prev, curr) => prev.images !== curr.images}
        labelCol={{ span: 4 }}
      >
        <Form.List
          name="images"
          labelCol={listMap.largeLayout.labelCol}
          wrapperCol={listMap.largeLayout.wrapperCol}
        >
          {(fields) => {
            return (
              <>
                {fields.map((field, idx) => {
                  const img = form.getFieldValue(['images', field.name]);
                  return (
                    <div
                      key={img.id}
                      style={{
                        display: 'inline-block',
                        border: '1px solid #888',
                        marginRight: 4,
                        borderRadius: 3,
                      }}
                    >
                      <div style={{ height: '50px', textAlign: 'center' }}>
                        <Form.Item
                          name={[field.name, 'url']}
                          valuePropName="src"
                        >
                          <img style={{ height: '50px' }} alt="" />
                        </Form.Item>
                      </div>
                      <div>
                        <Button
                          type="primary"
                          icon={<DownloadOutlined />}
                          onClick={() => onDownload(img.id)}
                        />
                        <Upload
                          onChange={(e) => uploadThumbImg(e, idx)}
                          showUploadList={false}
                        >
                          <Button>교체</Button>
                        </Upload>
                        <input
                          ref={inputRef}
                          hidden
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            );
          }}
        </Form.List>
      </Form.Item>
      <Form.Item label="심사 상태" name="status" labelCol={{ span: 4 }}>
        <Select
          options={[
            { label: '확인 중', value: 'READY' },
            { label: '등록 실패', value: 'CANCELED' },
            { label: '적합', value: 'MATCHED' },
            { label: '부적합', value: 'NOT_MATCHED' },
          ]}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.status !== curr.status}
      >
        {({ getFieldValue }) => {
          return getFieldValue('status') === 'CANCELED' ? (
            <Form.Item
              label="담당자 안내"
              name="comment"
              labelCol={{ span: 4 }}
              rules={[
                {
                  required: true,
                  message: '담당자 안내를 입력해주세요.',
                },
              ]}
            >
              <Input.TextArea placeholder="앱에 표시될 담당자 안내 내용을 입력해주세요." />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 20,
        }}
      >
        <Button type="primary" htmlType="submit">
          저장
        </Button>
        <Button danger onClick={onDelete}>
          삭제
        </Button>
      </Form.Item>
      <Divider />
    </Form>
  );
};

const RaRegisterHistory = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const getData = useCallback(async () => {
    const res = await fetchRaUserDnaReports(userId);
    setList(res);
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const removeItem = (id) => {
    setList(list.filter((item) => item.id !== id));
  };

  if (loading) return <Skeleton active />;
  return (
    <>
      {list.length > 0 ? (
        list.map((item) => (
          <RegisterForm item={item} key={item.id} remove={removeItem} />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default RaRegisterHistory;
