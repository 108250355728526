import React, {
  useState,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Table, Checkbox, Button, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router';
import useWindow from '../../../hooks/useWindow';
import { usePagination } from '../../../hooks/usePagination';
import {
  fetchAllClinicalTrialUpdateHistory,
  patchClinicalTrialUpdateHistory,
  deleteClinicalTrialUpdateHistory,
  deleteClinicalTrialUpdateHistories,
} from '../../../services/clinicalTrialUpdateHistoryService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import DiseaseTypeCell from '../diseaseType/link';
import { makeLink } from '../diseaseType/diseaseType';
import { EditableCell } from '../../../component/table';
import { postNotificationLegacy } from '../../../services/notificationService';
import { handleError } from '../../../hooks/useRequest';

const formatTimestamp = (text) =>
  text && dayjs(text).format('YYYY-MM-DD HH:mm');

const PushButton = ({ selected, initialize, setSelected }) => (
  <Button
    type="primary"
    disabled={selected.length === 0}
    onClick={() =>
      window.confirm('임상시험 업데이트 푸시를 전송하시겠습니까?') &&
      postNotificationLegacy({ type: 'CLINICAL_TRIAL', contentsIds: selected })
        .then(() => {
          initialize();
          setSelected([]);
        })
        .catch(handleError)
    }
  >
    푸시 전송
  </Button>
);

const BulkDeleteButton = ({ selected, initialize, setSelected }) => (
  <Button
    type="danger"
    disabled={selected.length === 0}
    onClick={() =>
      window.confirm('임상시험 업데이트 내역을 삭제하시겠습니까?') &&
      deleteClinicalTrialUpdateHistories(selected)
        .then(() => {
          initialize();
          setSelected([]);
        })
        .catch(handleError)
    }
  >
    선택 삭제
  </Button>
);

const ClinicalTrialUpdateHistory = () => {
  const history = useHistory();
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { state } = useLocation();
  const {
    apiParams: [notified, diseaseTypeId],
    setApiParams,
    initialize,
    data,
    loading,
    pageState,
    pagination,
  } = usePagination({
    call: fetchAllClinicalTrialUpdateHistory,
    initialPageState: state?.pageState,
    initialApiParams: [
      state?.notified === undefined ? false : state?.notified,
      state?.diseaseTypeId,
    ],
  });
  const [localData, dispatch] = useReducer((prevData, { type, payload }) => {
    switch (type) {
      case 'FETCH':
        return payload;
      case 'UPDATE':
        const { id } = payload;
        return prevData.map((clinicalTrialUpdateHistory) =>
          clinicalTrialUpdateHistory.id === id
            ? payload
            : clinicalTrialUpdateHistory,
        );
      default:
        return prevData;
    }
  }, []);
  const [selected, setSelected] = useState([]);

  const storeDispatch = useDispatch();
  const diseaseTypeData = useSelector(
    ({ diseaseTypeReducer }) => diseaseTypeReducer.diseaseTypes.data,
  );

  const diseaseTypeFilter = useMemo(
    () =>
      diseaseTypeData?.map(({ id, krName }) => ({
        label: krName,
        value: id,
      })),
    [diseaseTypeData],
  );

  useEffect(() => {
    storeDispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [storeDispatch]);

  useEffect(() => {
    if (data) dispatch({ type: 'FETCH', payload: data });
  }, [data, dispatch]);

  const onSave = useCallback(
    ({ id, value }) =>
      !window.confirm('수정하시겠습니까?') ||
      patchClinicalTrialUpdateHistory(id, { body: value })
        .then((payload) => {
          dispatch({ type: 'UPDATE', payload });
          return true;
        })
        .catch(() => alert('수정에 실패했습니다.')),
    [dispatch],
  );

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.ctkId.toString())) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.ctkId,
      dataType: 'clinicalTrial',
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      setApiParams([notified, diseaseTypeId], pageState);
    },
    [destroyWindowById, setApiParams, notified, diseaseTypeId, pageState],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const toClinicalTrialPage = useCallback(
    ({ krName: name, id }) => {
      history.replace('/clinicalTrialUpdateHistory', {
        ...state,
        pageState,
        notified,
        diseaseTypeId,
      });
      history.push('/diseaseTypeClinicalTrial', { name, diseaseTypeId: id });
    },
    [history, pageState, state, notified, diseaseTypeId],
  );

  const DeleteButton = useCallback(
    (_, { id }) => (
      <DeleteFilled
        className="deleteBtn"
        onClick={() =>
          window.confirm('삭제하시겠습니까?') &&
          deleteClinicalTrialUpdateHistory(id)
            .then(() => {
              setApiParams([notified, diseaseTypeId], pageState); // refresh
            })
            .catch(() => alert('삭제에 실패하였습니다.'))
        }
      />
    ),
    [setApiParams, notified, diseaseTypeId, pageState],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);

  const columns = [
    {
      title: (
        <div>
          선택&ensp;
          <Checkbox
            checked={selected.length === data.length}
            indeterminate={selected.length && data.length > selected.length}
            onClick={(e) => e.stopPropagation()}
            onChange={({ target }) =>
              setSelected(target.checked ? data.map(({ id }) => id) : [])
            }
          />
        </div>
      ),
      width: '5%',
      render: (_, { id }) => (
        <Checkbox
          checked={selected.includes(id)}
          onChange={({ target }) =>
            setSelected((prevSelected) => {
              const filtered = prevSelected.filter(
                (selectedId) => id !== selectedId,
              );
              if (target.checked) filtered.push(id);
              return filtered;
            })
          }
        />
      ),
    },
    {
      title: '한임정 ID',
      dataIndex: 'ctkId',
      width: '6%',
      render: makeLink(handleCreateEditWindow),
    },
    {
      title: '종류',
      dataIndex: 'type',
      width: '9%',
    },
    {
      title: '제목',
      dataIndex: 'title',
      width: '16%',
    },
    {
      title: '내용',
      dataIndex: 'data',
      editable: true,
      width: '26%',
      render: (text, { id }) => (
        <EditableCell
          text={text}
          id={id}
          onSave={onSave}
          componentType="textarea"
          inputProps={{
            type: 'text',
            maxLength: 50,
            showCount: true,
            autoSize: true,
          }}
          maxlength={50}
        />
      ),
    },
    {
      title: '알림 시각',
      dataIndex: 'notifiedAt',
      width: '9%',
      filteredValue: [notified],
      filters: [
        { text: 'O', value: true },
        { text: 'X', value: false },
      ],
      render: formatTimestamp,
    },
    {
      title: '관련 질환',
      dataIndex: 'diseaseTypes',
      width: '15%',
      filterDropdown: ({ visible }) => (
        <Select
          className="diseaseTypeDropdown"
          dropdownClassName="diseaseTypeDropdown"
          placement="bottomLeft"
          defaultValue={diseaseTypeId ?? null}
          allowClear
          open={visible}
          showSearch
          placeholder="질환을 선택하세요"
          options={diseaseTypeFilter}
          filterOption={(input, { label }) =>
            label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) =>
            setApiParams([notified, value], { ...pageState, current: 1 })
          }
        />
      ),
      filtered: diseaseTypeId !== undefined,
      filteredValue: diseaseTypeId ? [diseaseTypeId] : null,
      render: (diseaseTypes) => (
        <DiseaseTypeCell
          diseaseTypes={diseaseTypes}
          toClinicalTrialPage={toClinicalTrialPage}
        />
      ),
    },
    {
      title: '생성 시각',
      dataIndex: 'createdAt',
      width: '11%',
      render: formatTimestamp,
    },
    {
      title: '삭제',
      width: '3%',
      render: DeleteButton,
    },
  ];

  return (
    <>
      <PushButton
        selected={selected}
        initialize={initialize}
        setSelected={setSelected}
      />{' '}
      <BulkDeleteButton
        selected={selected}
        initialize={initialize}
        setSelected={setSelected}
      />
      <div className="searchResult">검색결과 {pagination.total ?? 0}개</div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={localData}
        pagination={pagination}
        loading={loading}
        rowClassName="editableRow"
        size="small"
        onChange={(_, filters, __, { action }) => {
          if (action !== 'filter') return;
          const notifiedFilter = filters.notifiedAt;
          const newNotified =
            notifiedFilter?.length === 1 ? notifiedFilter[0] : null;
          if (notified !== newNotified) setApiParams([newNotified]);
        }}
        bordered
      />
    </>
  );
};

export default ClinicalTrialUpdateHistory;
