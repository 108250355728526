import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  Layout,
  notification,
  Row,
  Typography,
} from 'antd';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import DndTable from '../../components/dndTable';
import { HEALTH_PROFILE_QUESTION_TYPE } from '../../../util/healthProfileConstants';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { RA_PAGE_TYPE } from '../../../util/raConstants';
import {
  copyRaPages,
  deleteRaPages,
  fetchRaPages,
  patchRaQuestionPageOrder,
} from '../../../services/raisingAwarenessService';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../../../services/utils';

const TagWrapper = ({ text }) => (
  <div className="tagWrapper" style={{ color: 'rgb(51, 51, 51)' }}>
    {text}
  </div>
);

const toRawText = (string) => {
  const div = document.createElement('div');
  div.innerHTML = string;
  return div.innerText;
};

const RaisingAwarenessPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [dataSource, setDataSource] = useState({
    [RA_PAGE_TYPE.INTRO]: [],
    [RA_PAGE_TYPE.SURVEY]: [],
    [RA_PAGE_TYPE.RESULT]: [],
    [RA_PAGE_TYPE.DNA]: [],
  });
  const [selectedRowKey, setSelectedRowKey] = useState({
    [RA_PAGE_TYPE.INTRO]: [],
    [RA_PAGE_TYPE.SURVEY]: [],
    [RA_PAGE_TYPE.RESULT]: [],
    [RA_PAGE_TYPE.DNA]: [],
  });
  const hasSelectedRow = Object.values(selectedRowKey).some(
    (v) => v.length > 0,
  );
  const hasUncopyableRow =
    selectedRowKey[RA_PAGE_TYPE.INTRO].length > 0 ||
    selectedRowKey[RA_PAGE_TYPE.DNA].length > 0;

  const [filter, setFilter] = useState({
    [RA_PAGE_TYPE.INTRO]: '',
    [RA_PAGE_TYPE.SURVEY]: '',
    [RA_PAGE_TYPE.RESULT]: '',
    [RA_PAGE_TYPE.DNA]: '',
  });
  const [total, setTotal] = useState({
    [RA_PAGE_TYPE.INTRO]: 0,
    [RA_PAGE_TYPE.SURVEY]: 0,
    [RA_PAGE_TYPE.RESULT]: 0,
    [RA_PAGE_TYPE.DNA]: 0,
  });
  const confirmRef = useRef([() => {}, () => {}, () => {}, () => {}]);
  const editLimited = state.isApp || state.hasAnsweredUser;

  // RA_PAGE_TYPE.SURVEY에서만 사용
  const [orderHistory, setOrderHistory] = useState([]);

  const { createWindow, destroyWindowById } = useWindow();

  const handleCreateNewPageWindow = () => {
    if (state.hasAnsweredUser) {
      notification.error({
        message: '이미 응답한 사용자가 있어 문항을 추가할 수 없습니다.',
        key: 'hasAnsweredUserError',
      });
      return;
    }
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'raisingAwarenessPage',
      raisingAwarenessId: state.raisingAwarenessId,
      title: state.title,
      hasAnsweredUser: state.hasAnsweredUser,
      isApp: state.isApp,
    });
  };

  const handleCreateDetailWindow = (id, type) => {
    createWindow({
      id: id,
      dataType: 'raisingAwarenessPage',
      raisingAwarenessId: state.raisingAwarenessId,
      raisingAwarenessPageId: id,
      title: state.title,
      hasAnsweredUser: state.hasAnsweredUser,
      isApp: state.isApp,
      pageType: type,
      firstQuestion: toRawText(dataSource[RA_PAGE_TYPE.SURVEY][0]?.title ?? ''),
    });
  };

  const getData = useCallback(async () => {
    const id = state.raisingAwarenessId;
    if (!id) return;
    const res = await fetchRaPages(id);
    setDataSource(res);
    if (Object.values(filter).every((v) => v.length === 0)) {
      const totalObj = Object.keys(res).reduce((prev, curr) => {
        prev[curr] = res[curr].length;
        return prev;
      }, {});
      setTotal(totalObj);
    }
  }, [state.raisingAwarenessId]);

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close' && id.length > 0) {
        destroyWindowById(id);
        getData();
      }
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    getData();
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [getData, receiveMessage]);

  const confirmOrder = async () => {
    if (!window.confirm('문항 순서를 확정하시겠습니까?')) return;
    try {
      await patchRaQuestionPageOrder(state.raisingAwarenessId, orderHistory);
      notification.success({
        message: '문항 순서를 저장했습니다.',
      });
      setOrderHistory([]);
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            'RA 프로그램이 진행중/완료 상태이거나 앱에 노출되어 있지 않은지 확인해 주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 페이지입니다.';
        }
      }
      notification.error({
        message: '문항 순서 변경에 실패했습니다.',
        description,
      });
    }
  };

  const onDelete = async () => {
    if (!window.confirm('선택한 페이지를 삭제하시겠습니까?')) return;
    const types = [
      'INTRO',
      'SURVEY',
      'NORMAL_RESULT',
      'DNA_TEST_REPORT_RESULT',
    ];

    try {
      await Promise.all(
        Object.values(selectedRowKey).map((ids, idx) =>
          deleteRaPages(state.raisingAwarenessId, types[idx], ids),
        ),
      );
      notification.success({
        message: '선택한 페이지를 삭제했습니다.',
      });
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            'RA 프로그램이 진행중/완료 상태이거나 앱에 노출되어 있지 않은지 확인해 주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 페이지입니다.';
        }
      }
      notification.error({
        message: '삭제에 실패했습니다.',
        description,
      });
    } finally {
      getData();
      setSelectedRowKey({
        [RA_PAGE_TYPE.INTRO]: [],
        [RA_PAGE_TYPE.SURVEY]: [],
        [RA_PAGE_TYPE.RESULT]: [],
        [RA_PAGE_TYPE.DNA]: [],
      });
    }
  };

  const onCopy = async () => {
    if (!window.confirm('선택한 페이지를 복사하시겠습니까?')) return;
    const types = [
      'INTRO',
      'SURVEY',
      'NORMAL_RESULT',
      'DNA_TEST_REPORT_RESULT',
    ];

    try {
      await Promise.all(
        Object.values(selectedRowKey).map((ids, idx) =>
          copyRaPages(state.raisingAwarenessId, types[idx], ids),
        ),
      );
      notification.success({
        message: '선택한 페이지를 복사했습니다.',
      });
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            'RA 프로그램이 진행중/완료 상태이거나 앱에 노출되어 있지 않은지 확인해 주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 페이지입니다.';
        }
      }
      notification.error({
        message: '복사에 실패했습니다.',
        description,
      });
    } finally {
      getData();
      setSelectedRowKey({
        [RA_PAGE_TYPE.INTRO]: [],
        [RA_PAGE_TYPE.SURVEY]: [],
        [RA_PAGE_TYPE.RESULT]: [],
        [RA_PAGE_TYPE.DNA]: [],
      });
    }
  };

  const onSearch = (keyword) => {
    confirmRef.current.forEach((ref) => ref(keyword));
    if (keyword.length > 0 && orderHistory.length > 0) {
      setOrderHistory([]);
      notification.warning({
        message: '문항 순서 변경이 저장되지 않고 초기화되었습니다.',
        key: 'disableDndWarning',
      });
      getData();
    }
  };

  const intro_columns = [
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'check',
      width: 60,
      align: 'center',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      render: (title, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          onClick={() => {
            handleCreateDetailWindow(record.id, RA_PAGE_TYPE.INTRO);
          }}
        >
          {title}
        </Button>
      ),
      onFilter: (value, record) =>
        record.title.includes(value) || record.subtitle?.includes(value),
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current[0] = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.INTRO]: keyword }));
          } else {
            setSelectedKeys(null);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.INTRO]: '' }));
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '부제목',
      key: 'subtitle',
      dataIndex: 'subtitle',
    },
    {
      title: 'CTA 연결',
      key: 'cta',
      dataIndex: ['cta', 'type'],
      render: (type) => (
        <TagWrapper
          text={
            type === 'SURVEY'
              ? '문항/답변'
              : type === 'DNA_TEST_REPORT_RESULT'
              ? '유전자 적합성 결과'
              : '완료'
          }
        />
      ),
    },
  ];

  const survey_columns = [
    {
      title: '순서 변경',
      dataIndex: 'sort',
      key: 'sort',
      width: 90,
      align: 'center',
    },
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'check',
      width: 60,
      align: 'center',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      render: (title, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          onClick={() => {
            handleCreateDetailWindow(record.id, RA_PAGE_TYPE.SURVEY);
          }}
        >
          {toRawText(title)}
        </Button>
      ),
      onFilter: (value, record) =>
        record.title.includes(value) || record.description?.includes(value),
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current[1] = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.SURVEY]: keyword }));
          } else {
            setSelectedKeys(null);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.SURVEY]: '' }));
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '문항 부가 설명',
      key: 'description',
      dataIndex: 'description',
      render: (description) => toRawText(description),
    },
    {
      title: '답변 타입',
      key: 'type',
      dataIndex: 'type',
      render: (type) => HEALTH_PROFILE_QUESTION_TYPE[type],
    },
    {
      title: '다음 문항 설정 여부',
      key: 'hasNextQuestion',
      dataIndex: 'hasNextQuestion',
      render: (hasNextQuestion) => (hasNextQuestion ? 'O' : 'X'),
    },
    {
      title: '응답 필수 / 선택',
      key: 'isAnswerRequired',
      dataIndex: 'isAnswerRequired',
      render: (isAnswerRequired) => (isAnswerRequired ? '필수' : '선택'),
    },
    {
      title: '보호자 동의 필요',
      key: 'hasNokAgreementRequiredAnswer',
      dataIndex: 'hasNokAgreementRequiredAnswer',
      render: (hasNokAgreementRequiredAnswer) =>
        hasNokAgreementRequiredAnswer ? 'O' : 'X',
    },
  ];

  const result_columns = [
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'check',
      width: 60,
      align: 'center',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      render: (title, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          onClick={() => {
            handleCreateDetailWindow(record.id, RA_PAGE_TYPE.RESULT);
          }}
        >
          {title}
        </Button>
      ),
      onFilter: (value, record) =>
        record.title.includes(value) || record.subtitle?.includes(value),
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current[2] = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.RESULT]: keyword }));
          } else {
            setSelectedKeys(null);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.RESULT]: '' }));
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '부제목',
      key: 'subtitle',
      dataIndex: 'subtitle',
    },
    {
      title: '다중 결과 페이지 여부',
      key: 'hasConditions',
      dataIndex: 'hasConditions',
      render: (hasConditions) => (hasConditions ? 'O' : 'X'),
    },
    {
      title: 'CTA 연결',
      key: 'cta',
      dataIndex: ['cta', 'type'],
      render: (type) => (
        <TagWrapper
          text={
            type === 'SURVEY'
              ? '문항/답변'
              : type === 'DNA_TEST_REPORT_RESULT'
              ? '유전자 적합성 결과'
              : '완료'
          }
        />
      ),
    },
  ];

  const dna_columns = [
    {
      title: '선택',
      dataIndex: 'rowSelection',
      key: 'rowSelection',
      type: 'check',
      width: 60,
      align: 'center',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      render: (title, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          onClick={() => {
            handleCreateDetailWindow(record.id, RA_PAGE_TYPE.DNA);
          }}
        >
          {title}
        </Button>
      ),
      onFilter: (value, record) =>
        record.title.includes(value) || record.subtitle?.includes(value),
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current[3] = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.DNA]: keyword }));
          } else {
            setSelectedKeys(null);
            setFilter((prev) => ({ ...prev, [RA_PAGE_TYPE.DNA]: '' }));
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '부제목',
      key: 'subtitle',
      dataIndex: 'subtitle',
    },
    {
      title: '적합성 여부',
      key: 'dnaTestReportResult',
      dataIndex: 'dnaTestReportResult',
      render: (dnaTestReportResult) =>
        dnaTestReportResult === 'MATCHED' ? '적합' : '부적합',
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="문항 / 답변 관리"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{state.title}</Typography.Title>
            <Row justify="space-between">
              <Row>
                <Button type="primary" onClick={() => history.goBack()}>
                  RA 목록으로 돌아가기
                </Button>
                <Button
                  type="primary"
                  disabled={orderHistory.length === 0}
                  onClick={confirmOrder}
                  style={{ marginLeft: 8 }}
                >
                  문항 순서 확정
                </Button>
              </Row>
              <Row justify="end">
                <span className="searchResult">
                  검색결과{' '}
                  {Object.values(total).reduce((prev, curr) => prev + curr)}개
                </span>
                <Input.Search
                  placeholder="검색어를 입력해주세요."
                  allowClear
                  className="searchStyle"
                  onSearch={onSearch}
                />
              </Row>
            </Row>
            <Divider style={{ margin: '0 0 12px 0' }} />
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              <Row style={{ gap: 6 }}>
                <Button
                  type="primary"
                  onClick={handleCreateNewPageWindow}
                  disabled={editLimited}
                >
                  추가
                </Button>
                <Button
                  type="primary"
                  disabled={!hasSelectedRow || editLimited || hasUncopyableRow}
                  onClick={onCopy}
                >
                  복사
                </Button>
                <Button
                  type="primary"
                  disabled={!hasSelectedRow || editLimited}
                  onClick={onDelete}
                  danger
                >
                  삭제
                </Button>
              </Row>
            </Row>
            <DndTable
              title={() => (
                <Typography.Title level={5}>소개 페이지</Typography.Title>
              )}
              columns={intro_columns}
              dataSource={dataSource[RA_PAGE_TYPE.INTRO]}
              setDataSource={(v) =>
                setDataSource((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.INTRO]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.INTRO]) : v,
                }))
              }
              setSelectedRowKey={(v) =>
                setSelectedRowKey((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.INTRO]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.INTRO]) : v,
                }))
              }
              selectedRowKey={selectedRowKey[RA_PAGE_TYPE.INTRO]}
              onChange={(_, __, ___, extra) => {
                setTotal((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.INTRO]: extra.currentDataSource.length,
                }));
              }}
            />
            <Divider style={{ margin: '20px 0' }} />
            <DndTable
              title={() => (
                <Typography.Title level={5}>
                  문항 / 답변 페이지
                </Typography.Title>
              )}
              disableDnd={editLimited || filter[RA_PAGE_TYPE.SURVEY].length > 0}
              columns={survey_columns}
              dataSource={dataSource[RA_PAGE_TYPE.SURVEY]}
              setDataSource={(v) => {
                setDataSource((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.SURVEY]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.SURVEY]) : v,
                }));
              }}
              onDragEnd={(active, over) => {
                if (active === over) return;

                // activeIndex > overIndex 이면 overIndex를 +1 해줘야함
                const activeIndex = dataSource[RA_PAGE_TYPE.SURVEY].findIndex(
                  (data) => data.id === active,
                );
                let overIndex = dataSource[RA_PAGE_TYPE.SURVEY].findIndex(
                  (data) => data.id === over,
                );
                if (activeIndex < overIndex) overIndex += 1;

                const destinationId =
                  dataSource[RA_PAGE_TYPE.SURVEY][overIndex]?.id;

                // 마지막 순서로 드래그한 경우 nextOrderId를 null로 보냄 - RARENOTE-4152
                const isLast =
                  overIndex === dataSource[RA_PAGE_TYPE.SURVEY].length;

                setOrderHistory((prev) => [
                  ...prev,
                  { id: active, nextOrderId: isLast ? null : destinationId },
                ]);
              }}
              setSelectedRowKey={(v) =>
                setSelectedRowKey((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.SURVEY]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.SURVEY]) : v,
                }))
              }
              selectedRowKey={selectedRowKey[RA_PAGE_TYPE.SURVEY]}
              onChange={(_, __, ___, extra) => {
                setTotal((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.SURVEY]: extra.currentDataSource.length,
                }));
              }}
            />
            <Divider style={{ margin: '20px 0' }} />
            <DndTable
              title={() => (
                <Typography.Title level={5}>결과 페이지</Typography.Title>
              )}
              columns={result_columns}
              dataSource={dataSource[RA_PAGE_TYPE.RESULT]}
              setDataSource={(v) =>
                setDataSource((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.RESULT]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.RESULT]) : v,
                }))
              }
              setSelectedRowKey={(v) =>
                setSelectedRowKey((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.RESULT]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.RESULT]) : v,
                }))
              }
              selectedRowKey={selectedRowKey[RA_PAGE_TYPE.RESULT]}
              onChange={(_, __, ___, extra) => {
                setTotal((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.RESULT]: extra.currentDataSource.length,
                }));
              }}
            />
            <Divider style={{ margin: '20px 0' }} />
            <DndTable
              title={() => (
                <Typography.Title level={5}>
                  유전자 적합성 결과 페이지
                </Typography.Title>
              )}
              columns={dna_columns}
              dataSource={dataSource[RA_PAGE_TYPE.DNA]}
              setDataSource={(v) =>
                setDataSource((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.DNA]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.DNA]) : v,
                }))
              }
              setSelectedRowKey={(v) =>
                setSelectedRowKey((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.DNA]:
                    typeof v === 'function' ? v(prev[RA_PAGE_TYPE.DNA]) : v,
                }))
              }
              selectedRowKey={selectedRowKey[RA_PAGE_TYPE.DNA]}
              onChange={(_, __, ___, extra) => {
                setTotal((prev) => ({
                  ...prev,
                  [RA_PAGE_TYPE.DNA]: extra.currentDataSource.length,
                }));
              }}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RaisingAwarenessPage;
