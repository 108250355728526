import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, notification, Select } from 'antd';
import 'antd/dist/antd.css';
import { userCreators } from '../../../store/reducers/user.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { fetchDiseaseHealthProfileAnswers } from '../../../services/healthProfileService';
import * as permissions from '../../../util/permission';

const projects = ['뇌전증', '신경섬유종증 1형', '두셴 근디스트로피'];

const DiseaseHealthProfileExcelDown = () => {
  const dispatch = useDispatch();
  const [selectDiseaseId, setSelectDiseaseId] = useState('');

  const downloadExcel = useCallback((data) => {
    const xlsx = require('xlsx');
    const wb = xlsx.utils.book_new();

    Object.entries(data).forEach(([key, value], _index) => {
      const ws = xlsx.utils.aoa_to_sheet(value);
      const sheetTitle = key.slice(0, 31);
      xlsx.utils.book_append_sheet(wb, ws, sheetTitle);
    });

    const firstKey = Object.keys(data)[0];
    xlsx.writeFile(wb, `${firstKey}.xlsx`);

    notification.success({
      message: '건강설문 다운로드가 완료 되었습니다.',
      description: '건강설문 다운로드가 완료 되었습니다. ',
    });
  }, []);

  const excelRequest = useCallback(
    (diseaseId) => () =>
      fetchDiseaseHealthProfileAnswers(diseaseId)
        .then((response) => downloadExcel(response))
        .catch(({ _data, message = `건강설문 다운로드에 실패했습니다.` }) => {
          notification.warn({
            message: '건강설문 다운로드에 실패했습니다.',
            description: message,
          });
        }),
    [downloadExcel],
  );

  const { permission, diseaseTypeInfo } = useSelector((state) => ({
    permission: state?.userReducer.permission.data,
    diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
      ? state.diseaseTypeReducer.diseaseTypes.data
          .filter(({ krName }) => projects.includes(krName))
          .map(({ id, krName }) => ({
            id,
            krName,
          }))
      : null,
  }));

  const getData = useCallback(() => {
    dispatch(userCreators.fetchAllUser.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  return (
    <div style={{ float: 'left', display: 'flex' }}>
      <Select
        allowClear
        style={{ width: 250, marginRight: 10 }}
        placeholder="액셀 추출할 연구 신청 선택"
        onChange={(_, data) => {
          setSelectDiseaseId(data?.id);
        }}
      >
        {diseaseTypeInfo &&
          diseaseTypeInfo.map((diseaseTypeInfoData) => (
            <Select.Option
              id={diseaseTypeInfoData?.id}
              key={diseaseTypeInfoData?.id}
              value={diseaseTypeInfoData?.id}
            >
              {diseaseTypeInfoData?.krName}
            </Select.Option>
          ))}
      </Select>
      {permission?.includes(permissions.ACCOUNT_USER) && (
        <Button
          type="primary"
          style={{ marginRight: 10 }}
          onClick={excelRequest(selectDiseaseId)}
          disabled={!selectDiseaseId}
        >
          설문 다운
        </Button>
      )}
    </div>
  );
};

export default DiseaseHealthProfileExcelDown;
