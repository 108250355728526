import React, { useEffect, useReducer, useState, useCallback } from 'react';
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Select,
  Table,
  Button,
  Alert,
} from 'antd';
import 'antd/dist/antd.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getBytesOfString } from '../../../util';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { notificationCreators } from '../../../store/reducers/notification.reducer';
import { userCreators } from '../../../store/reducers/user.reducer';
import { useMutate } from '../../../hooks/useRequest';
import { postNotificationLegacy } from '../../../services/notificationService';

const clientId = process.env.REACT_APP_CLIENT_ID || '';

const IndividualNotification = () => {
  // declare dispatch function & variables for fetching data from store
  const dispatch = useDispatch();
  const { users, notUsers, notificationInfo } = useSelector((state) => {
    return {
      users: state.userReducer.adminUser.data,
      notUsers: state.userReducer.notAdminUser.data,
      notificationInfo: state.notificationReducer.notificationIndividual.data,
    };
  }, shallowEqual);

  const initialState = {
    selectedAccount: null,
    body: '',
    selectedAccountGroups: [],
    userIds: [],
    title: '',
  };

  const { Content } = Layout;
  const { Option } = Select;
  const { Search } = Input;
  const [form] = Form.useForm();
  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  // component state
  const [state, setState] = useReducer(stateReducer, initialState);
  const [reload, setReload] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [dataSource, setDataSource] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [isPost, setIsPost] = useState(false);
  const [listLoad, setListLoad] = useState(false);

  // dispatch to fetch data
  const getData = useCallback(() => {
    dispatch(userCreators.fetchAdminUser.request({ clientId, isAdmin: true }));
    dispatch(
      userCreators.fetchNotAdminUser.request({ clientId, isAdmin: false }),
    );
  }, [dispatch]);

  // data fetching on mount
  useEffect(() => {
    getData();
  }, [getData]);

  const getListData = useCallback(() => {
    dispatch(notificationCreators.fetchAllNotificationIndividual.request());
    setListLoad(true);
  }, [dispatch]);

  useEffect(() => {
    setListLoad(false);
    setDataSource(notificationInfo);
    setFilteredData(notificationInfo?.length);
  }, [notificationInfo]);

  const {
    mutate: putNotification,
    done: isPosted,
    initialize: initializePost,
  } = useMutate(postNotificationLegacy, {
    type: 'INDIVIDUAL',
    userIds: state.selectedAccountGroups?.map((user) => JSON.parse(user).id),
    body: state.body,
    title: state.title || null,
  });

  // check ready for distribute
  const distribute = () => {
    const contentsByte = getBytesOfString(state.body);
    const titlesByte = getBytesOfString(state.title);
    const maxByte = 250;

    if (titlesByte > maxByte) {
      window.alert(`제목은 최대 50자까지 입력할 수 있습니다.`);
      return;
    }

    if (contentsByte > maxByte) {
      window.alert(`내용은 최대 50자까지 입력할 수 있습니다.`);
      return;
    }

    if (window.confirm('알림/푸시를 보내시겠습니까?')) {
      putNotification();
      setReload(true);
    }
  };

  // reloading after distribute
  useEffect(() => {
    if (reload && isPosted) {
      form.resetFields();
      setReload(false);
      initializePost();
      getData();
      window.alert(`전송 완료하였습니다.`);
    }
  }, [isPosted]);

  const groupOptions = (value, text) => {
    if (value === null) {
      return JSON.stringify({
        id: null,
        text: null,
      });
    }
    return JSON.stringify({
      id: value?.id,
      text: value?.[text],
    });
  };

  const onFinish = (values) => {
    setState({
      selectedAccountGroups: values.select,
      title: values.title,
      body: values.body,
    });
    setShowAlert(false);
    setIsPost(true);
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    if (isPost) {
      distribute();
      setIsPost(false);
    }
  }, [isPost]);

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(notificationInfo);
      setFilteredData(notificationInfo?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
      },
    },
    {
      title: '질환',
      dataIndex: 'diseaseType',
      key: 'diseaseType',
      sorter: {
        compare: (a, b) => a?.diseaseType?.localeCompare(b?.diseaseType),
      },
    },
    {
      title: '알림/푸시 내용',
      dataIndex: 'message',
      key: 'message',
      sorter: {
        compare: (a, b) => a?.message?.localeCompare(b?.message),
      },
    },
    {
      title: '발송 시간',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabOperation" link="individualNotification" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="메세지 알림/푸시"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="select"
                label="유저"
                hasFeedback
                rules={[{ required: true, message: '유저를 선택해주세요' }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="유저를 선택해주세요"
                >
                  {users?.map((user) => (
                    <Option key={groupOptions(user, 'email')}>
                      {user.email}
                    </Option>
                  ))}
                  {notUsers?.map((user) => (
                    <Option key={groupOptions(user, 'email')}>
                      {user.email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="알림/푸시 제목"
                name="title"
                rules={[
                  {
                    required: true,
                    message: '제목을 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="알림/푸시 내용"
                name="body"
                rules={[
                  {
                    required: true,
                    message: '내용를 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={reload}
                  style={{ width: 100 }}
                >
                  보내기
                </Button>
                <Form.Item name="field" noStyle>
                  &nbsp;&nbsp;실서비스에 반영할 내용을 등록하고 보내기 버튼을
                  눌러주세요.
                </Form.Item>
              </Form.Item>

              {showAlert && (
                <Row>
                  <Col span={20} offset={4} style={{ marginBottom: 10 }}>
                    <Alert
                      message="에러"
                      description="필수값을 확인해주세요."
                      type="error"
                      showIcon
                    />
                  </Col>
                </Row>
              )}
            </Form>

            <div className="divider" />

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>
            <Button type="primary" disabled={listLoad} onClick={getListData}>
              리스트 불러오기
            </Button>

            <Table
              columns={columns}
              loading={listLoad}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default IndividualNotification;
